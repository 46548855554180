import React from "react";
import {
  isMobile
} from "react-device-detect";

/** Importing styled components */
import {
  StyledCarousel,
  StyledCarouselSlide,
  StyledCarouselSlideContent,
  H1,
  Paragraph,
  StyledCarouselButton,
  StyledCarouselSlideContainer,
  FluidImage
} from "./StyledComponent";
/** Importing styles for react Slick */
import "./../styles/slider.css";
import { ArrowRight } from "react-feather";

const Carousel = ({ slidesData, height }) => {
  const settings = {
    accesibility: true,
    arrows: false,
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000
  };

  return (
    <>
      <StyledCarousel height={height} {...settings}>
        {slidesData.edges.map(el => {
          return (
            <StyledCarouselSlide key={el.node.id}>
            {isMobile ? (<FluidImage
                fluid={el.node.frontmatter.featuredImagemb.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={el.node.frontmatter.title}
              />) : <FluidImage
                fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={el.node.frontmatter.title}
              />}
              <StyledCarouselSlideContainer>
                <StyledCarouselSlideContent>
                  <H1 align="left" color="var(--secondary)">
                    {el.node.frontmatter.title}
                  </H1>
                  <Paragraph align="left" color="var(--black)">
                    {el.node.frontmatter.description}
                  </Paragraph>
                  <StyledCarouselButton to={el.node.frontmatter.link}>
                    <ArrowRight
                      size={40}
                      color="var(--white)"
                      strokeWidth={1}
                    />
                  </StyledCarouselButton>
                </StyledCarouselSlideContent>
              </StyledCarouselSlideContainer>
            </StyledCarouselSlide>
          );
        })}
      </StyledCarousel>
    </>
  );
};

export default Carousel;
