import React, { Fragment} from "react";
import { graphql } from "gatsby";
/** Importing functional components */
import DefaultPage from "../layout/DefaultPage";
import Carousel from "../components/Carousel";
/** Importing styled components */
import { HomeLayout, RootBorder } from "../components/StyledComponent";

const IndexPage = ({ data: { getSlides } }) => {
  return (
    <Fragment>
      <DefaultPage pageTitle="Inicio">
        <HomeLayout>
          <RootBorder />
          <Carousel height="100%" slidesData={getSlides} />
        </HomeLayout>
      </DefaultPage>
    </Fragment>
  );
};

export default IndexPage;

export const homeQuery = graphql`
  query getHomeData {
    getSlides: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "slider" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            link
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
            featuredImagemb {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  }
`;
